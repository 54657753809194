import React, {useState, useEffect} from 'react'
import {
    Card, CardBody,
    CardHeader, Row, Col,
    Button
} from 'reactstrap'
import PermissionService from '../../redux-modules/services/permission/permission'
import Order from './Order'
import {orderActions, globalActions, franchiseActions} from "../../redux-modules/actions"
import {Link} from 'react-router-dom'
import {connect} from "react-redux"
import Pagination from '../../catalogs/Globals/Pagination/Pagination'
import SelectDropdown from '../../catalogs/Globals/Select/Select'
import OrderHeader from '../OrderHeader' 
import OrderFilter from '../OrderFilter'
import { updateDimensions } from '../../redux-modules/services/mobileService'
import ExportOrders from './Export'
const isMobile = updateDimensions()


const status = [
    {
        value:'accepted',
        label:'accepted',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'progress',
        label:'progress',
        color:'success',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'delivering',
        label:'delivering',
        color:'warning',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'cancel',
        label:'cancel',
        color:'dark',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'done',
        label:'done',
        color:'secondary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'new',
        label:'new',
        color:'danger',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'stolen',
        label:'stolen',
        color:'custom-orange',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'driver accepted',
        label:'driver accepted',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'driver rejected',
        label:'driver rejected',
        color:'warning',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'driver cancelled',
        label:'driver cancelled',
        color:'dark',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'out for delivery',
        label:'out for delivery',
        color:'warning',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'not verified',
        label:'not verified',
        color:'danger',
        className:'fa fa-check fa-lg mr-1'
    },
    // {
    //     value:'first_pickup_attempt',
    //     label:'first pickup attempt',
    //     color:'primary',
    //     className:'fa fa-check fa-lg mr-1'
    // },
    // {
    //     value:'unassigned',
    //     label:'unassigned',
    //     color:'warning',
    //     className:'fa fa-check fa-lg mr-1'
    // },
    // {
    //     value:'arrived',
    //     label:'arrived',
    //     color:'primary',
    //     className:'fa fa-check fa-lg mr-1'
    // },
    // {
    //     value:'out_for_delivery',
    //     label:'out for delivery',
    //     color:'warning',
    //     className:'fa fa-check fa-lg mr-1'
    // },
    // {
    //     value:'picked_up',
    //     label:'picked_up',
    //     color:'danger',
    //     className:'fa fa-check fa-lg mr-1'
    // },
    // {
    //     value:'on_his_way',
    //     label:'on his way',
    //     color:'warning',
    //     className:'fa fa-check fa-lg mr-1'
    // },
    // {
    //     value:'first_delivery_attempt',
    //     label:'first delivery attempt',
    //     color:'primary',
    //     className:'fa fa-check fa-lg mr-1'
    // },
    // {
    //     value:'first_pickup_attempt',
    //     label:'first pickup attempt',
    //     color:'primary',
    //     className:'fa fa-check fa-lg mr-1'
    // },
];

const paymentMethods = [
    {
        value:'cash',
        label:'cash',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'zelle',
        label:'zelle',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'venmo',
        label:'venmo',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'cashapp',
        label:'cashapp',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'paypal',
        label:'paypal',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'payOnDelivery',
        label:'Pay On Delivery',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'credit_card',
        label:'Credit Card',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'creditCard',
        label:'Credit Card Stripe',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'squareCreditCard',
        label:'Credit Card Square',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'apple_pay',
        label:'Apple Pay',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    },
    {
        value:'onlinePayment',
        label:'Online Payment',
        color:'primary',
        className:'fa fa-check fa-lg mr-1'
    }
];


function OrderList(props) {
    const { pageOrders, getPagFranchiseNotRemoved, account, filter, dataFilter, getAllOrderStatus, orderStatistics, ordersCount, statistics, statusList, location } = props
    const [showEntriesD, setShowEntriesD] = useState(parseInt(localStorage.getItem('orderPageSizeD')) || 20)
    const [showEntriesN, setShowEntriesN] = useState(parseInt(localStorage.getItem('orderPageSizeN')) || 20)
    let [pageN, setPagN] = useState(parseInt(localStorage.getItem('orderPageN')) || 1)
    let [pageD, setPagD] = useState(parseInt(localStorage.getItem('orderPageD')) || 1)
    const [filterTab, setFilterTab] = useState(localStorage.getItem('orderStatusTab') == 'true' ? true : false)
    const [statusTab, setStatusTab] = useState(localStorage.getItem('orderFilterTab')  || 'all')
    const [access, setAccess] = useState({
        modify: false,
        access: false,
        create: false,
        update: false,
        delete: false,
        archive_and_recover: false
    });

    const accessCreateStyle = !access.create ? { pointerEvents: 'none', opacity: '.5'} : {}

    const currentPage = filterTab ? pageN: pageD;
    const pageSize = filterTab ? showEntriesN : showEntriesD;

    useEffect(() => {
        getPagFranchiseNotRemoved(account.token, 1, 1000)
        getAllOrderStatus(account.token)
        setAccess(PermissionService.getPermission(props.permissions, props.location))
    }, [])

    useEffect(() => {
        pageOrders(account.token, currentPage, pageSize, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: filterTab} })
        orderStatistics(account.token, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: filterTab} })
    }, [props.mainNotifications])

    function onSizeChange(event) {
        pageOrders(account.token, currentPage, event, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: filterTab} })
        if (filterTab) {
            localStorage.setItem('orderPageSizeN', event)
            setShowEntriesN(event)
        } else {
            localStorage.setItem('orderPageSizeD', event)
            setShowEntriesD(event)
        }
        onCurrentChange(1)
    }

    function onCurrentChange(event) {
        if (filterTab) {
            localStorage.setItem('orderPageN', event)
            setPagN(event)
        } else {
            localStorage.setItem('orderPageD', event)
            setPagD(event)
        }
    }

    function changeStatusTab(event) {
        pageOrders(account.token, 1, pageSize, { ...filter ,...dataFilter, ...{ ['status']: event === 'all' ? null : event, deleted: filterTab} })
        orderStatistics(account.token, { ...filter ,...dataFilter, ...{ ['status']: event === 'all' ? null : event, deleted: filterTab} })
        setStatusTab(event)
        onCurrentChange(1)
        localStorage.setItem('orderFilterTab', event) 
    }

    function changeFilterTab(event) {
        pageOrders(account.token, 1, pageSize, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: event} })
        orderStatistics(account.token, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: event} })
        setFilterTab(event)
        localStorage.setItem('orderStatusTab', event) 
    }

    function exportData() {
        return props.exportOrders(account.token, 1, 5000, { ...filter, ...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: filterTab} })
    }

    return (
        <div>
            <OrderFilter 
                {...props}
                page = {currentPage}
                showEntries = {pageSize}
                paginationTable = {props.pageOrders}
                filterTab = {filterTab}
                statistics={statistics}
                orderStatistics={props.orderStatistics}
                ordersCount={ordersCount}
                statusTab={statusTab}
                onCurrentChange={(event) => { onCurrentChange(event) }}
                showStatistics={!!props.permissions.find(({value}) => value == 'sale/order/statistics')}
                actionType = 'ORDER'/>
                <Col col="2" sm="4" md="4" xl className="mb-1 d-flex justify-content-end">
                    <div className="main-btns">
                        {
                            !!ordersCount && access.delete &&
                            <ExportOrders
                                exportData={exportData}
                                loading={props.loadingObject.export}
                            />
                        }
                        <div className="mw ml-2">
                            <Link  
                                style={accessCreateStyle}  
                                to={`${props.match.path}/create`}>
                                <Button 
                                    block 
                                    color="success">
                                    <i className="fa fa-plus-circle fa-lg mr-1"> </i>
                                    Create
                                </Button>
                            </Link>
                        </div>
                    </div>
                </Col>
            <Card>
                <CardHeader>
                    <Row>
                        <Col col="4" sm="12" md="4"  className="my-1">
                            <Button 
                                style={{margin: "0px 10px 10px 0px"}} 
                                color="ghost-primary" 
                                onClick={() => changeFilterTab(false)}
                                className={filterTab ? '' : 'active'}>
                                <i className="fa fa-align-justify mr-2"> </i>
                                All published Orders
                            </Button>
                            <Button 
                                style={{marginBottom: "10px"}} 
                                color="ghost-primary" 
                                onClick={() => changeFilterTab(true)}
                                className={filterTab ? 'active' : ''}>
                                <i className="fa fa-file-archive-o fa-lg mr-2"></i>
                                Archived Orders
                            </Button>
                        </Col>
                    </Row>
                    <OrderHeader 
                        filterTab = { statusTab }
                        _changeFilterTab = { (e) => changeStatusTab(e) }
                        statusList = { statusList }
                    />
                </CardHeader>
            </Card>
            <CardBody>
                <div className="ordersTable">
                    {
                        !isMobile &&
                        <CardHeader className='px-2 d-flex flex-row justify-content-start overflow-auto'>
                            <Col col="6" xs="6" sm="4" md="2" lg="1" className="px-2">Order Id</Col>
                            <Col col="8" xs="8" sm="4" md="3" lg="1" className="px-2">Status</Col>
                            <Col col="4" xs="4" sm="3" md="2" lg="1" className="px-2">Price</Col>
                            <Col col="6" xs="6" sm="4" md="2" lg="1" className="px-2">Total</Col>
                            <Col col="6" xs="6" sm="4" md="2" lg="1" className="px-2">User</Col>
                            <Col col="6" xs="6" sm="4" md="2" lg="1" className="px-2">Tax</Col>
                            <Col col="10" xs="10" sm="4" md="4" lg="2" className="px-2">Address</Col>
                            <Col col="6" xs="6" sm="3" md="2" lg="1" className="px-2">Pay</Col>
                            <Col col="10" xs="10" sm="4" md="4" lg="1" className="px-2">Date</Col>
                            <Col col="6" xs="6" sm="3" md="2" lg="1" className="px-2">Show details</Col>
                            <Col col="6" xs="6" sm="3" md="2" lg="1" className="px-2">Actions</Col>
                        </CardHeader>
                    }
                    {
                        props.orders.map((elem) => 
                            <div key={elem.orderId}>
                                    <Order
                                        key = {elem.orderId}
                                        elem = {elem}
                                        orders = {props.orders}
                                        id = {elem.mongoId}
                                        account = {props.account}
                                        statusList={
                                            access.modify ?
                                            status 
                                            :
                                            (elem.status == 'new') ?
                                            status.filter((item) => item.value !== 'not verified')
                                            :
                                            (elem.status == 'not verified') ? 
                                            status 
                                            :  
                                            status.filter((item) => item.value !== 'new' && item.value !== 'not verified')
                                        }
                                        statusTab = { statusTab }
                                        filterTab = { filterTab }
                                        page = { currentPage }
                                        showEntries = { pageSize }
                                        filter = { filter }
                                        location = { location }
                                        dataFilter = { dataFilter }
                                        access = { access }
                                        paymentMethods={ paymentMethods }
                                    />
                            </div>                                    
                        )
                    }

                    {
                        !props.orders.length && !props.loading 
                        &&
                        <article id="Simple-Content-Center-Main" className="No-Records-Found">
                            <figure>
                                <figcaption>
                                    <p>{'Does not exists results for show.'}</p>
                                </figcaption>
                            </figure>
                        </article>
                    }
                </div>
                {/*____________________Pagination________________________*/}
            <nav>
                {
                    (ordersCount > pageSize) &&
                    <div className="pagination-block">
                        <Pagination
                            layout="sizes, prev, pager, next" 
                            total={ordersCount}
                            currentPage={currentPage}                    
                            pageSize = {pageSize} 
                            onCurrentChange = {(event) => {
                                pageOrders(account.token, event, pageSize, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: filterTab} })
                                onCurrentChange(event)
                            }}
                        />
                        <SelectDropdown 
                            data = {['10', '20', '30', '40', '50', '100']}
                            disabled={false}
                            name={'selectOpen'} 
                            value={pageSize}
                            changeSelect={(e) => onSizeChange(e)}
                        />
                    </div>
                }
            </nav>
                {/*____________________Pagination________________________*/}
            </CardBody>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
       items: state.newOrder,
       orders: state.newOrders.pagOrder,
       isOpen: state.newOrders.isOpen,
       statusList: state.newOrders.statusList,
       loading: state.newOrders.loading,
       filter: state.newOrders.initialFilter,
       dataFilter: state.newOrders.dataFilter,
       statistics: state.newOrders.statistics,
       mainNotifications: state.notifications.mainNotifications,
       ordersCount: state.newOrders.pagOrderCount,
       permissions: state.account.userGroup.permissions,
       exportedOrders: state.newOrders.exportedOrders,
       loadingObject: state.newOrders.loadingObject
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPagFranchiseNotRemoved: (token, page, count) => dispatch(franchiseActions.getPaginationNotRemoved(token, page, count)),
        getAllOrderStatus: (jwt) => dispatch(orderActions.getAllOrderStatus(jwt)), 
        pageOrders: (jwt, page, count, orderStatus) => dispatch(orderActions.getPaginationPg(jwt, page, count, orderStatus)),
        exportOrders: (jwt, page, count, orderStatus) => dispatch(orderActions.exportOrders(jwt, page, count, orderStatus)),
        orderStatistics: (jwt, orderStatus) => dispatch(orderActions.statistics(jwt, orderStatus)),
        filterDataSearch: (key, value, actionType ) => dispatch(globalActions.filterDataSearch(key, value, actionType))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);